<!--结算申请核实-->
<template>
  <div class="courseUser">
    <div class="pageContol listWrap templateList">
      <div class="framePage">
        <div class="framePage-title">
          <span>
            <em>当前位置：</em>
            <a href="javascript:;">结算管理</a>
            <i>></i>
            <a href="javascript:;" @click="init" class="cur-a">结算申请核实</a>
          </span>
        </div>
        <div class="framePage-body">
          <div
            class="operationControl"
            style="
              display: flex;
              flex-direction: column;
              align-items: flex-start;
            "
          >
            <div class="searchbox">
              <div title="班级编码" class="searchboxItem ci-full">
                <span class="itemLabel">班级编码:</span>
                <el-input
                  v-model="selectData.projectCode"
                  type="text"
                  size="small"
                  clearable
                  placeholder="请输入班级编码"
                />
              </div>
              <div title="班级名称" class="searchboxItem ci-full">
                <span class="itemLabel">班级名称:</span>
                <el-input
                  v-model="selectData.projectName"
                  type="text"
                  size="small"
                  clearable
                  placeholder="请输入班级名称"
                />
              </div>
              <div title="所属机构:" class="searchboxItem ci-full">
                <span class="itemLabel">所属机构:</span>
                <el-select
                  size="small"
                  v-model="selectData.compId"
                  remote
                  :remote-method="getCompanyList"
                  filterable
                  clearable
                  placeholder="请至少输入两个字搜索"
                  ><el-option
                    v-for="item in CompanyList"
                    :key="item.compId"
                    :label="item.compName"
                    :value="item.compId"
                  ></el-option>
                </el-select>
              </div>
              <div title="结算单号" class="searchboxItem ci-full">
                <span class="itemLabel">结算单号:</span>
                <el-input
                  v-model="selectData.billNo"
                  type="text"
                  size="small"
                  placeholder="请输入结算单号"
                  clearable
                />
              </div>
            </div>
             <div class="searchbox" style="margin-top: 10px">
              <div title="申请类型:" class="searchboxItem ci-full">
                <span class="itemLabel">申请类型:</span>
                <el-select
                  v-model="selectData.billApplySource"
                  placeholder="请选择"
                  size="small"
                  clearable
                >
                  <el-option
                    v-for="item in billApplySourceList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
              <div title="结算单状态:" class="searchboxItem ci-full">
                <span class="itemLabel">结算单状态:</span>
                <el-select
                  v-model="selectData.auditType"
                  placeholder="请选择"
                  size="small"
                  clearable
                >
                  <el-option
                    v-for="item in auditTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="df">
                <el-button
                  type="primary"
                  class="bgc-bv"
                  round
                  @click="getData()"
                  >查询</el-button
                >
              </div>
            </div>
          </div>
          <div class="framePage-scroll">
            <div class="ovy-a">
              <el-table
                ref="multipleTable"
                :data="tableData"
                :height="tableHeight"
                size="small"
                tooltip-effect="dark"
                style="width: 100%"
                :header-cell-style="tableHeader"
                stripe
              >
                <el-table-column
                  label="序号"
                  align="center"
                  type="index"
                  :index="indexMethod"
                />
                <el-table-column
                  label="结算机构"
                  align="left"
                  prop="compName"
                  show-overflow-tooltip
                />
                <el-table-column
                  label="申请结算时间"
                  align="left"
                  show-overflow-tooltip
                  prop="createTime"
                />
                <el-table-column
                  label="班级数量"
                  align="left"
                  show-overflow-tooltip
                  prop="projectNum"
                  width="100"
                />
                <el-table-column
                  label="待结算人数"
                  align="left"
                  show-overflow-tooltip
                  prop="settlementUserCount"
                  width="100"
                />
                <el-table-column
                  label="结算单号"
                  align="left"
                  show-overflow-tooltip
                  prop="billNo"
                />
                <el-table-column
                  label="申请类型"
                  align="left"
                  show-overflow-tooltip
                  prop="billApplySource"
                >
                  <template slot-scope="{ row }">
                    {{
                      $setDictionary("BILL_APPLY_SOURCE", row.billApplySource)
                    }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="结算单状态"
                  align="left"
                  show-overflow-tooltip
                  prop="auditType"
                >
                  <template slot-scope="{ row }">
                    {{ $setDictionary("FD_AUDIT_TYPE", row.auditType) }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="备注"
                  align="left"
                  show-overflow-tooltip
                  prop="remark"
                />
                <el-table-column label="操作" width="200" align="center">
                  <div slot-scope="{ row }">
                    <el-button
                      v-if="buttonRules(row)[0]"
                      type="text"
                      size="mini"
                      style="padding: 0 5px"
                      @click="verify(row)"
                      >核实</el-button
                    >
                    <el-button
                      v-if="buttonRules(row)[1]"
                      type="text"
                      size="mini"
                      style="padding: 0 5px"
                      @click="viewDetails(row)"
                      >查看详情</el-button
                    >
                    <el-button
                      v-if="buttonRules(row)[2]"
                      type="text"
                      size="mini"
                      style="padding: 0 5px"
                      @click="revocation(row)"
                      >撤回</el-button
                    >
                  </div>
                </el-table-column>
                <Empty slot="empty" />
              </el-table>
            </div>
          </div>
          <PageNum
            :apiData="apiData"
            @sizeChange="sizeChange"
            @getData="getData"
          />
        </div>
      </div>
    </div>
    <el-dialog
      title="结算单审核"
      :visible.sync="dialogVisible"
      v-if="dialogVisible"
      width="50%"
      center
    >
      <div class="flexdcc">
        <el-descriptions title="机构信息">
          <el-descriptions-item label="机构名称">{{
            dialogData.compName
          }}</el-descriptions-item>
          <el-descriptions-item label="申请日期">{{
            dialogData.createTime
          }}</el-descriptions-item>
        </el-descriptions>
        <el-descriptions title="班级明细" />
        <el-table
          :data="dialogTabData"
          size="mini"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="tableHeader"
          stripe
        >
          <el-table-column
            label="序号"
            align="center"
            type="index"
            :index="indexMethod"
          />
          <el-table-column
            label="班级编码"
            align="left"
            prop="projectCode"
            show-overflow-tooltip
          />
          <el-table-column
            label="班级名称"
            align="left"
            min-width="200"
            prop="projectName"
            show-overflow-tooltip
          />
          <el-table-column
            label="注册人数"
            align="left"
            width="80"
            prop="registerNum"
            show-overflow-tooltip
          />
          <el-table-column
            label="结业人数"
            align="left"
            width="80"
            prop="graduateNum"
            show-overflow-tooltip
          />
          <el-table-column
            label="已结算人数"
            align="left"
            width="80"
            prop="settledNum"
            show-overflow-tooltip
          />
          <el-table-column
            label="开始时间"
            align="left"
            prop="startDate"
            show-overflow-tooltip
          />
          <el-table-column
            label="结束时间"
            align="left"
            prop="endDate"
            show-overflow-tooltip
          />
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <template v-if="dialogFooter">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="verifyConfirm(true)" class="bgc-bv"
            >通 过</el-button
          >
          <el-button type="primary" @click="verifyConfirm(false)" class="bgc-bv"
            >驳 回</el-button
          >
        </template>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "settlementVerify",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      //搜索object
      selectData: {
        projectName: "", //班级名称
        projectCode: "", //班级编码
        compId: "", //所属机构
        billApplySource: "", //申请来源
        auditType: "01", //结算单状态
      },
      // 所属机构list
      CompanyList: [],
      //结算单申请来源-字典数据
      billApplySourceList: [],
      //结算单状态-字典数据
      auditTypeList: [],
      //行政区划prop
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      dialogVisible: false, //结算单审核
      dialogFooter: false, //结算单核实or查看详情
      dialogData: {}, //结算单审核弹窗机构信息数据
      dialogTabData: [], //结算单审核弹窗班级明细数据
    };
  },
  computed: {},
  created() {
    this.getDictionaries(); //获取字典
    this.getTableHeight(); //获取列表高度
    this.getData(); //获取列表
  },
  methods: {
    // 计算操作规则
    buttonRules(row) {
      if (row.billApplySource == "0") {
        // 商务申请
        return [0, 1, 0];
      }
      if (row.billApplySource == "1" || row.billApplySource == "3") {
        // 机构申请 || 代理商申请
        if (row.auditType == "01") {
          return [1, 0, 0];
        } else {
          return [0, 1, 0];
        }
      }
      if (row.billApplySource == "2") {
        // 运营申请
        if (row.auditType == "05") {
          return [0, 1, 1];
        } else {
          return [0, 1, 0];
        }
      }
    },
    // 获取字典
    getDictionaries() {
      // 申请来源
      let arr1 = this.$setDictionary("BILL_APPLY_SOURCE", "list");
      for (const key in arr1) {
        this.billApplySourceList.push({
          label: arr1[key],
          value: key,
        });
      }
      // 结算单状态
      let arr2 = this.$setDictionary("FD_AUDIT_TYPE", "list");
      for (const key in arr2) {
        this.auditTypeList.push({
          label: arr2[key],
          value: key,
        });
      }
    },
    //获取列表
    getData(pageNum) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        ...this.selectData,
      };
      this.doFetch({
        url: "/biz/new/bill/operation/billPageList",
        params,
        pageNum,
      });
    },
    // 核实
    verify(row) {
      this.dialogFooter = true;
      this.verifyDialog(row);
    },
    // 核实 - 确认
    verifyConfirm(flag) {
      if (flag) {
        // 通过
        this.verifyInterface(flag);
      } else {
        // 驳回
        this.$prompt("请输入驳回理由", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          inputValidator: (val) => !!val,
          inputErrorMessage: "驳回理由不能为空",
        })
          .then(({ value }) => {
            this.verifyInterface(flag, value);
          })
          .catch(() => {});
      }
    },
    // 核实 - 接口
    verifyInterface(flag, value = null) {
      this.$post("/biz/new/bill/operation/billVerify", {
        billId: this.dialogData.billId,
        isPass: flag,
        remark: value,
      })
        .then((res) => {
          if (res.status == 0) {
            this.dialogVisible = false;
            this.$message.success("核实成功");
            this.getData();
          }
        })
        .catch(() => {
          return;
        });
    },
    // 查看详情
    viewDetails(row) {
      this.dialogFooter = false;
      this.verifyDialog(row);
    },
    // 撤回
    revocation(row) {
      this.$post("/biz/new/bill/operation/applyWithdrawn", {
        billId: row.billId,
      })
        .then((res) => {
          if (res.status == 0) {
            this.$message.success("撤回成功");
            this.getData();
          }
        })
        .catch(() => {
          return;
        });
    },
    // 结算核实弹窗
    verifyDialog(row) {
      this.$post("/biz/new/bill/operation/getVerifyInfo", {
        billId: row.billId,
      })
        .then((res) => {
          if (res.status == 0) {
            this.dialogData = row;
            this.dialogTabData = res.data || [];
            this.dialogVisible = true;
          }
        })
        .catch(() => {
          return;
        });
    },
    //获取列表高度
    getTableHeight(opDom = true, page = true) {
      let tHeight = window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 4) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    //两个字搜索 - 所属机构
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
  },
  mounted: function () {},
};
</script>

<style lang="less" scope>
.courseUser {
  height: 100%;
}
.el-textarea {
  resize: none;
  .el-textarea__inner {
    height: 200px;
  }
}
.listWrap {
  .el-icon-arrow-down {
    font-size: 12px;
    &:before {
      content: "\e6df" !important;
    }
  }
  .el-tabs {
    flex: 1;
    display: flex;
    flex-direction: column;
    .el-tabs__content {
      flex: 1;
    }
  }
}
.btnBox {
  .el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    background: #a9a9a9;
    border-color: #a9a9a9;
    color: #fff;
  }
}
.confirmButtonClass {
  color: #fff !important;
  background-color: #5c6be8 !important;
  border-color: #5c6be8 !important;
}
.el-table th.is-left {
  > .cell {
    text-align: left;
  }
}
.el-table th.is-right {
  > .cell {
    text-align: right;
  }
}
</style>
